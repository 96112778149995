import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
@Component({
  selector: 'app-enovsky-banner',
  templateUrl: './enovsky-banner.component.html',
  styleUrls: ['./enovsky-banner.component.scss', 
              '../../../public/module/style.css',
              '../../../public/module/bootstrap.css',
               '../../../public/module/theme.css',
              '../../../public/module/animate.css'],
})
export class EnovskyBannerComponent {
  constructor(
    private elRef: ElementRef,
  ) {}
  @ViewChild('sidenav') sidenav!: MatSidenav;
  scrollY = 0;
  distance = 50;
  speed = 24;
  currentRoute = '';
  currentYear = new Date().getFullYear();
  shareUserData(){

  }
  autoScrollTo(identifier: string) {
    const targetElement = this.elRef.nativeElement.querySelector('#' + identifier);
    if (!targetElement) return;

    const targetY = targetElement.getBoundingClientRect().top + window.scrollY;
    const currentY = window.scrollY;
    const bodyHeight = document.body.offsetHeight;
    const yPos = currentY + window.innerHeight;

    if (yPos > bodyHeight) return;

    const animator = setTimeout(() => {
      this.autoScrollTo(identifier);
    }, 24);

    if (currentY < targetY - this.distance) {
      const scrollY = currentY + this.distance;
      window.scroll(0, scrollY);
    } else {
      clearTimeout(animator);
    }
  }
}
