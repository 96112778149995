<div *ngIf="page=='confirmation' " class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title" >Caution !!!</h2></div>
    </div>
    <div class="modal-body">
        <div>{{data['text']}}</div>
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="onClose()">Close</button>
        <button type="button" class="button-validate-small" (click)="validateAction()">Continue</button>
    </div>
</div>

<div *ngIf="page=='upload_content' " class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title" >Caution !!!</h2></div>
    </div>
    <div class="modal-body">
        <div>{{data['text']}}</div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;">
        <div class="label-style" fxFlex="20">Supplier</div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="40">
            <mat-select placeholder="Supplier" [formControl]="supplierControl" (selectionChange)="switchNewSupplier()">
                <mat-option [value]="'new'">New Supplier</mat-option>
                <mat-option *ngFor="let element of suppliers; let i = index" [value]="element">{{ element }}</mat-option>
            </mat-select>
        </div>
        <div class="separator"></div>
        <div *ngIf="isNewSupplier" class="class-mat-field" fxFlex="40">
            <input type="text" class="col-input" [formControl]="supplierNameControl"  placeholder="Type supplier" value="">
        </div>
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="onClose()">Close</button>
        <button type="button" class="button-validate-small" (click)="sendUploadValidation()">Continue</button>
    </div>
</div>

<div *ngIf="page=='new_project' " class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title" >Create new Project</h2></div>
    </div>
    <div class="modal-body">
        <input #project_name type="text" class="col-input" placeholder="Type name" value="">
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="closeProjectCreation()">Close</button>
        <button type="button" class="button-validate-small" (click)="sendProjectName(project_name.value)">Continue</button>
    </div>
</div>

<div *ngIf="page=='video_show' " class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title" >{{data['title']}}</h2></div>
    </div>
    <div id="demo_container" class="modal-dialog modal-xl modal-content" >
        <video controls preload="metadata" style="width: 100%; height: 50%" loop autoplay>
          <source src="{{data['url']}}">video can't play
        </video>
    </div>
    <hr>
</div>






