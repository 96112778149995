import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild} from '@angular/core';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
@Component({
  selector: 'app-side-virtualcampus',
  templateUrl: './side-virtualcampus.component.html',
  styleUrls: ['./side-virtualcampus.component.scss']
})
export class SideVirtualCampusComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private sharedService: SharedService
    ) {}
  activePage = 'vca-content-project';
  @Input() userInfos:  { [x:string]: any } = new Map();
  virtualCampusPages = [{id_page: 'vca-content-project', name: 'Edit Content', 'icon': 'edit_document', group: 'content'}, {id_page: 'vca-create-project', name: 'Create Project', icon: 'add_notes', group: 'content'}, {id_page: 'vca-upload-content', name: 'Upload Content', icon: 'upload', group: 'content'}, {id_page: 'vca-content', name: 'Manage Content', icon: 'auto_stories', group: 'content'}, 
    {id_page: 'vca-student-dashboard', name: 'Dashboard', icon: 'dashboard', group: 'admin'},{id_page: 'vca-student-manager', name: 'Student Manager', icon: 'manage_accounts', group: 'admin'},
    {id_page: 'vca-manage-department', name: 'Department Manager', icon: 'school', group: 'admin'},
    {id_page: 'vca-promotion', name: 'Promotion', 'icon': 'thumb_up_off_alt', group: 'admin'}, {id_page: 'vca-advertisment', name: 'Advertisment', 'icon': 'campaign', group: 'admin'},
  ];
  contentMenus:{ [x:string]: any }[] = [];
  adminMenus:{ [x:string]: any }[] = [];
  ngOnInit(){
    let authorizations = this.userInfos?.['authorization']?.virtualcampus || [];
    this.virtualCampusPages = authorizations.length? this.virtualCampusPages.filter((item:any)=> authorizations.includes(item.id_page)): [];
    this.activePage = this.userInfos?.['navigation_infos']?.vca_last_page || this.activePage;
    this.contentMenus = this.virtualCampusPages.filter((item:any)=> item.group == 'content');
    this.adminMenus = this.virtualCampusPages.filter((item:any)=> item.group == 'admin');
  }
  
  showHidePage(pageId:string){
    this.activePage = pageId;
    this.sharedService.sendData({side_campus_page_id: pageId, close_side_nav: true});

  }
}
