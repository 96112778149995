<div class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title">{{rowInfos['project_name']}}: Project Setting </h2></div>
    </div>
    <div class="modal-body">
        <div fxLayout="row" fxLayout.xs="column">
            <div class="label-style" fxFlex="10">Action</div>
            <div class="separator"></div>
            <div class="class-mat-field" fxFlex="30">
                <mat-select placeholder="Action" [formControl]="actionControl" (selectionChange)="switchAction()">
                    <ng-container *ngFor="let row of projectActions; let i = index">
                        <mat-option *ngIf="userInfos['privilege'] >= row['level']" [value]="row['key']">{{ row['value'] }}</mat-option>
                      </ng-container>
                </mat-select>
            </div>
            <div class="separator"></div>
            <div class="class-mat-field" fxFlex="30">
                <mat-select *ngIf="actionMode == 'select'" placeholder="User" [formControl]="userControl" (selectionChange)="updateWave()">
                    <input class="col-input-filter" [formControl]="userInputControl">
                    <mat-option [value]="0">Choose</mat-option>
                    <mat-option [value]="result['id_user']" *ngFor="let result of allUsers; let i = index">{{result['first_name']}} {{result['last_name']}} ({{result['email']}})</mat-option>
                </mat-select>
                <input *ngIf="actionMode == 'input'" type="text" class="col-input" style="margin: 1px;" placeholder="Type name" [formControl]="actionValueControl">
            </div>
            <div class="separator"></div>
            <div class="class-mat-field" fxFlex="30">
                <mat-select *ngIf="actionMode == 'select'" placeholder="Set Wave" [formControl]="waveControl">
                    <mat-option [value]="0">New wave</mat-option>
                    <mat-option [value]="result['id']" *ngFor="let result of userWaves; let i = index">{{result['name']}}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="close()">Close</button>
        <button type="button" class="button-validate-small" (click)="applyAction()">Validate</button>
    </div>
</div>