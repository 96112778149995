import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import config from 'config/default.json';
import { TableComponent } from 'src/app/back-office/components/table/table.component';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss', '../../components/table/table.component.scss']
})
export class UserAdminComponent extends TableComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  enovskyPages = [{id_page: 'documentation', name: 'Documentation', icon: 'mark_unread_chat_alt'}, {id_page: 'user-admin', name: 'User Admin', icon: 'groups'}, {id_page: 'server-admin', name: 'Server Admin', icon: 'engineering'}];
  virtualCampusPages = [{id_page: 'vca-content-project', name: 'Edit Content', 'icon': 'edit_document', group: 'content'}, {id_page: 'vca-create-project', name: 'Create Project', icon: 'add_notes', group: 'content'}, {id_page: 'vca-upload-content', name: 'Upload Content', icon: 'upload', group: 'content'}, {id_page: 'vca-content', name: 'Manage Content', icon: 'auto_stories', group: 'content'}, 
    {id_page: 'vca-student-dashboard', name: 'Dashboard', icon: 'dashboard', group: 'admin'},{id_page: 'vca-student-manager', name: 'Student Manager', icon: 'manage_accounts', group: 'admin'},
    {id_page: 'vca-manage-department', name: 'Department Manager', icon: 'school', group: 'admin'},
    {id_page: 'vca-promotion', name: 'Promotion', 'icon': 'thumb_up_off_alt', group: 'admin'}, {id_page: 'vca-advertisment', name: 'Advertisment', 'icon': 'campaign', group: 'admin'},
  ];
  virtualClassPages = [{id_page: 'vcl-content-project', name: 'Edit Content', 'icon': 'edit_document', group: 'content'}, {id_page: 'vcl-create-project', name: 'Create Project', icon: 'add_notes', group: 'content'}, {id_page: 'vcl-upload-content', name: 'Upload Content', icon: 'upload', group: 'content'}, {id_page: 'vcl-content', name: 'Manage Content', icon: 'auto_stories', group: 'content'},
    {id_page: 'vcl-student-dashboard', name: 'Dashboard', icon: 'groups', group: 'admin'}, {id_page: 'vcl-student-manager', name: 'Student Manager', icon: 'manage_accounts', group: 'admin'},
     {id_page: 'vcl-manage-department', name: 'Manage Institution', icon: 'school', group: 'admin'},
    {id_page: 'vcl-promotion', name: 'Promotion', 'icon': 'thumb_up_off_alt', group: 'admin'}, {id_page: 'vcl-advertisment', name: 'Advertisment', 'icon': 'campaign', group: 'admin'},
  ];

  userAuthorized:boolean = false;

  ngOnInit(): void {
    this.loadUserData();
  }
  loadUserData(){
    this.sharedService.sendData({in_progress: true});
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/administration/load_user', {}).subscribe((response)=>{
      this.dataSource = response;
      this.showedRows = this.dataSource;
      this.sharedService.sendData({in_progress: false});
    });
  }
  applyUpdate(row:any){
    this.sharedService.sendData({in_progress: true});
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/administration/update_user', row).subscribe((response:any)=>{
      
      this.helper.showToast(response.message); 
      this.sharedService.sendData({in_progress: false});
    });
  }
  updatePrivilege(row:any, element:any){
    row['privilege'] = element;
  }
  toggleUserAuthorized(row:any){
    row['authorized'] = !row['authorized'];
  }
  checkPage(key:string, row:any, page:any, element:any){
    let authorizedPages = row['authorization'][key];
    let newPage = page.id_page;
    element.checked ? authorizedPages.push(newPage): authorizedPages = authorizedPages.filter((item:any)=>item !== newPage);
    row['authorization'][key] = authorizedPages
  }
  toggleRow(row:any, clickedElm:any, rowTab:any){
    let toExpand = true;
    for (var tdTab of rowTab.querySelectorAll('.to_skip_element')){
      if(tdTab.contains(clickedElm)){
        toExpand = false;
        break;
      }
    }
    if(toExpand){
      row['expanded'] =! row['expanded'];
    }
  }
  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.cdRef.detectChanges();
  }
}
