<div fxLayout="row" fxLayout.xs="column" style="width: 100vw;">
    <div class="class-mat-field-left" fxFlex="40">
        <div fxLayout="row" style="padding-bottom: 5px;">
            <div fxFlex="20%"></div>
            <div fxLayout="row" fxFlex="80%">
                <div fxFlex="50%" fxLayoutAlign="center center">
                    <button class="button-validate-small" (click) = "applyUpdate()">Classify</button>
                </div>
                <div fxFlex="50%" class="label-style" fxLayoutAlign="center center">
                    <input #is_classified class="form-check-input" [checked]="rowInfos['state'].includes('classified')" style="font-size:large; cursor: pointer; " type="checkbox" role="switch" id="is_classified" (change)="updateState('classified', is_classified.checked)">
                    <span>Classified</span>
                </div>
            </div>
        </div>
        <div class="container" style="height: 320px;">
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Country</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Country" [formControl]="countryControl" (selectionChange)="updateRegion()">
                        <input class="col-input-filter" [formControl]="searchCountryControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredCountries; let i = index">{{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Cycle</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Cycle" [formControl]="cycleControl" (selectionChange)="updateInstitution()">
                        <input class="col-input-filter" [formControl]="searchCycleControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredCycles; let i = index">{{element['name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Region</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Region" [formControl]="regionControl" (selectionChange)="updateInstitution()">
                        <input class="col-input-filter" [formControl]="searchRegionControl">
                        <mat-option [value]="0">Belongs to parent</mat-option>
                        <mat-option [value]="element['id']" *ngFor="let element of filteredRegions; let i = index">{{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Institution</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Institution" [formControl]="institutionControl" (selectionChange)="updateClass()">
                        <input class="col-input-filter" [formControl]="searchInstitutionControl">
                        <mat-option [value]="0">Belongs to parent</mat-option>
                        <mat-option [value]="element['id']" *ngFor="let element of filteredInstitutions; let i = index">{{element['name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Class</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Class" [formControl]="classControl" (selectionChange)="updateStudy()">
                        <input class="col-input-filter" [formControl]="searchClassControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredClasses; let i = index">{{element['name']}}: {{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Study</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Study" [formControl]="studyControl" class="multiselect" (selectionChange)="updateModule()" multiple>
                        <input class="col-input-filter" [formControl]="searchStudyControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredStudies; let i = index">
                            <span class="mat-option-content">{{element['name']}}</span>
                            <span class="mat-option-checkbox">
                                <mat-icon *ngIf="selectedElement(studyControl, element)" class="mat-icon material-icons check-icon" fontIcon="done"></mat-icon>
                            </span>
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Module</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Module" [formControl]="moduleControl" (selectionChange)="updateSubject()">
                        <input class="col-input-filter" [formControl]="searchModuleControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredModules; let i = index">{{element['name']}}: {{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Year</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Year" [formControl]="yearControl">
                        <input class="col-input-filter" [formControl]="searchYearControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredYears; let i = index">{{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Period</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Period" [formControl]="periodControl">
                        <input class="col-input-filter" [formControl]="searchPeriodControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredPeriods; let i = index">{{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Section</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Section" [formControl]="sectionControl" (selectionChange)="updateSubject()">
                        <input class="col-input-filter" [formControl]="searchSectionControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredSections; let i = index">{{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="separator"></div>
            <div fxLayout="row">
                <div class="label-style" fxFlex="20%">Type</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Type" [formControl]="typeControl" (selectionChange)="updateSubject()">
                        <input class="col-input-filter" [formControl]="searchTypeControl">
                        <mat-option [value]="element['id']" *ngFor="let element of filteredTypes; let i = index">{{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div *ngIf="showContentSubject" fxLayout="row" style="padding-top: 15px;">
                <div class="label-style" fxFlex="20%">Subject</div>
                <div fxFlex="80%">
                    <mat-select placeholder="Answer Name" [formControl]="subjectControl">
                        <input class="col-input-filter" [formControl]="searchSubjectControl">
                        <mat-option [value]="element['id_content_subject']" *ngFor="let element of filteredSubjects; let i = index">{{element['full_name']}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div fxLayout="row" style="padding-top: 15px;">
                <div class="label-style" fxFlex="20%">Name</div>
                <div fxFlex="80%">
                    <input class="col-input-filter" [formControl]="nameControl" placeholder="Name" style="margin: 0;">
                </div>
            </div>
            <div fxLayout="row" style="padding-top: 15px;">
                <div class="label-style" fxFlex="20%">Web Link</div>
                <div fxFlex="80%">
                    <input class="col-input-filter" [formControl]="linkControl" placeholder="Url" style="margin: 0;">
                </div>
            </div>
        </div>
        
    </div>
    <div class="class-mat-field-right" fxFlex="60">
        <div *ngIf="!isRawClassify" fxLayout="row" style="padding-bottom: 5px;">
            <div fxFlex="20%"></div>
            <div fxLayout="row" fxFlex="80%">
                <div fxFlex="20%" fxLayoutAlign="center center">
                    <button class="button-validate-small" (click)="lunchEdition()">Go Edit</button>
                </div>
                <div fxFlex="20%"></div>
                <div *ngIf="hasEdit" fxFlex="20%" class="label-style" fxLayoutAlign="center center">
                    <input #is_cleaned class="form-check-input" [checked]="rowInfos['state'].includes('edited')" style="font-size:large; cursor: pointer; " type="checkbox" role="switch" id="is_cleaned" (change)="updateState('edited', is_cleaned.checked)">
                    <span>Edited</span>
                </div>
                <div *ngIf="hasEdit" fxFlex="40%" style="position: relative; display: flex; align-items: center; margin-left: 15px;">
                    <label>Edit {{hasEdit}}</label>
                    <span class="switch-toggle" [ngClass]="{'switch-checked': pdfMode == 'raw'}" (click)="switchEditRaw()">
                      <small></small>
                    </span>
                    <label>Raw</label>
                </div>
            </div>
        </div>
        <app-pdf-viewer *ngIf="isPdfFileReady && pdfMode == 'raw'" [pdfData]="pdfRawData" class="pdf-container" hidden></app-pdf-viewer>
        <app-pdf-viewer *ngIf="!isRawClassify && isPdfFileReady && pdfMode == 'edit' && hasEdit" [pdfData]="pdfEditData" class="pdf-container" hidden></app-pdf-viewer>
    </div>
</div>