import { Component, ElementRef, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';
@Component({
  selector: 'app-vca-student-dashboard',
  templateUrl: './vca-student-dashboard.component.html',
  styleUrls: ['./vca-student-dashboard.component.scss']
})
export class VcaStudentDashboardComponent {
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {}
  
  @Output() inProgress = new EventEmitter<any>();
  @Input() userInfos: { [x:string]: any } = new Map();
  pageId = 'vca-dashboard'
  pdfData: { [x:string]: any } = new Map();
  modes:{ [x:string]: string|number }[] = [];

  data:{[x: string]: any} = {};
  platformInfos:{[x: string]: any} = {origin: 'virtualcampus', key: 'vca', root: 'VIRTUALCAMPUS'}
  ngOnInit(): void {

  }
  loadDashboardData(data:any){
    this.inProgress.emit(true);
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/dashboard/load', {filter_data: data, platform_infos: this.platformInfos}).subscribe((response)=>{
     
      this.data = response;
    }) 
  }
}
