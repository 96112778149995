<div style="width: 100%; height: 100px; display: flex; justify-content: center;">
    <img src="assets/logos/logo_enovsky.png" style="height: 100px;">
  </div>
<div style="padding-top: 2rem;">
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <h3 style="color: blue;">Content Manager</h3>
        </mat-expansion-panel-header>
        <mat-list-item [ngClass]="{'active-page' : activePage == page['id_page']}" *ngFor="let page of contentMenus"  (click)="showHidePage(page['id_page'])">
            <div fxLayout="row" fxLayoutAlign="space-between center" > 
                <mat-icon class="icon-leading" aria-hidden="false" fontIcon="{{page['icon']}}"></mat-icon>
                <span class="text">{{page['name']}}</span>
                <mat-icon class="icon-trailing" aria-hidden="false" fontIcon="arrow_forward_ios"></mat-icon>
            </div>
        </mat-list-item>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <h3 style="color: blue;">Administration</h3>
        </mat-expansion-panel-header>
        <mat-list-item [ngClass]="{'active-page' : activePage == page['id_page']}" *ngFor="let page of adminMenus"  (click)="showHidePage(page['id_page'])">
            <div fxLayout="row" fxLayoutAlign="space-between center" > 
                <mat-icon class="icon-leading" aria-hidden="false" fontIcon="{{page['icon']}}"></mat-icon>
                <span class="text">{{page['name']}}</span>
                <mat-icon class="icon-trailing" aria-hidden="false" fontIcon="arrow_forward_ios"></mat-icon>
            </div>
        </mat-list-item>
    </mat-expansion-panel>
</div>
