import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import config from 'config/default.json';
import { TableComponent } from 'src/app/back-office/components/table/table.component';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { RawSettingComponent } from './raw-setting/raw-setting.component';

@Component({
  selector: 'app-project-creation',
  templateUrl: './project-creation.component.html',
  styleUrls: ['./project-creation.component.scss','../../components/table/table.component.scss']
})
export class ProjectCreationComponent extends TableComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() data:{[x: string]: any}[]= [];
  @Input() platformInfos: { [x:string]: any } = new Map();
  pdfData: { [x:string]: any } = new Map();
  suppliers: any[] = [];
  formats: any[] = [];

  ngOnInit(): void {
    this.buildData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) this.buildData();
  }
  buildData(){
    this.dataSource = this.data.map((row) => {
      row["expanded"] = false;
      return row;
    });
    this.formats = Array.from(new Set(this.data.map(el=>el['format'])));
    this.suppliers = Array.from(new Set(this.data.map(el=>el['supplier'])));
    this.showedRows = this.dataSource;
  }
  getFileUrl(row:any){
    this.sharedService.sendData({in_progress: true});
    let data = {
      table: 'generate_files_urls',
      files_path: {file: this.platformInfos['root']+'/'+row['file_path']+'.pdf'}
    }
    //Get file
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    this.apiService.queryData(config.service.cloud.url + '/generate_files_urls', formData).subscribe(async(response:any)=>{
      let fileUrl = response['file'].file_url;
      this.pdfData = {url: fileUrl, row: row, height: 300, refresh: true};
      this.sharedService.sendData({in_progress: false});
    });
  }
  rawSetting(){
    let selectedRows = this.selectRows();
    if(selectedRows.length){
      const dialogRef = this.dialog.open(RawSettingComponent, {
        width: '70%',
        position: {top: '30px' },
        data: {user_infos: this.userInfos, selected_rows: selectedRows, platform_infos: this.platformInfos}
      });
      dialogRef.afterClosed().subscribe((response:any) => {
        if(response && response.action == 'delete_content'){
          this.dataSource = this.dataSource.filter((el:any)=> !response.delete_rows.includes(el.id));
          this.showedRows = this.showedRows.filter((el:any)=> !response.delete_rows.includes(el.id));
        }
      });
    }else this.helper.showToast('Select contents first...');


  }
  

  toggleRow(row:any, clickedElm:any, rowTab:any){
    let toExpand = true;
    for (var tdTab of rowTab.querySelectorAll('.to_skip_element')){
      if(tdTab.contains(clickedElm)){
        toExpand = false;
        break;
      }
    }
    if(toExpand){
      row['expanded'] =! row['expanded'];
      this.cdRef.detectChanges();
      if(row['expanded']){
        this.getFileUrl(row);
      }
    }
  }
  removeChildsFromElement(element:any){
    while (element && element.hasChildNodes()) {
      element.removeChild(element.lastChild);
    }
  }

  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.cdRef.detectChanges();
  }
}
