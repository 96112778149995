<section>
    <h2>Student</h2>
    <app-vcl-filter [pageId]="pageId" [userInfos]="userInfos" [modes]="modes" (filterDataOutputEvent)="loadStudentData($event)">
    </app-vcl-filter>
    <div style="text-align:left; padding: 20px;">
        <input class="float-right col-input" style="width: 180px; float:right;" (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
        <p>Total: {{this.showedRows.length}}</p>
    </div>
    <div class="table_div">
        <table>
            <thead>
            <tr>
                <th><div class="column-name">Id</div></th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'belongs', 'string')"></mat-icon>
                    <div class="column-name">Belongs</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'belongs')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'pseudo', 'string')"></mat-icon>
                    <div class="column-name">Pseudo</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'pseudo')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'email', 'string')"></mat-icon>
                    <div class="column-name">Email</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'email')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'platform', 'string')"></mat-icon>
                    <div class="column-name">Platform</div>
                    <mat-select placeholder="Choose" style="height: 22px;" (selectionChange)="applyFilterLocal($event, 'platform')">
                        <mat-option [value]="''">ALL</mat-option>
                        <mat-option *ngFor="let element of platforms" [value]="element">{{element}}</mat-option>
                    </mat-select>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'current_credit', 'number')"></mat-icon>
                    <div class="column-name">Current crédit</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'current_credit')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'active_date', 'date')"></mat-icon>
                    <div class="column-name">Last Active Date</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'active_date')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'created_date', 'date')"></mat-icon>
                    <div class="column-name">Created Date</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'created_date')"></div>
                </th>
                <th>
                    <div style="display: flex; justify-content: center;"><input #checkedAllRef type="checkbox" class="form-check-input" style="font-size:large; cursor: pointer; " (change)="selectAll($event.target)"></div>
                    
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let row of showedRows; let i = index">
                <tr (click)="toggleRow(row, $event.target)">
                    <td>{{row['id_row']}}</td>
                    <td matTooltip="{{ row['belongs'] }}" matTooltipPosition="below">{{ row['belongs'] }}</td>
                    <td matTooltip="{{ row['pseudo'] }}" matTooltipPosition="below">{{ row['pseudo'] }}</td>
                    <td matTooltip="{{ row['email'] }}" matTooltipPosition="below">{{ row['email'] }}</td>
                    <td>{{ row['platform'] }}</td>
                    <td>{{ row['current_credit'] }}</td>
                    <td>{{ row['active_date'] }}</td>
                    <td>{{ row['created_date'] }}</td>
                    <td><input type="checkbox" class="form-check-input" style="font-size:large; cursor: pointer; " [checked]="row['checked']" (change)="selectRow($event.target, i)"></td>
                </tr>
                <tr class="expand_detail" *ngIf="row['expanded']">
                    <td colspan="9">
                        <div>Here is my details</div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="showedRows.length === 0">
                <tr ><td colspan="11" style="text-align: center;">No matching records found</td></tr>
            </tbody>
        </table>
    </div>
</section>
