<section id="headline" style="background-image: url('public/module/banniere_store_1296x729.png');">
    <div class="container py-11">
        <div class="row">
            <div>
                <div class="headline-content py-lg-11">
                    <h1>Virtual Campus</h1>
                    <p class="subhealine">La première plateforme numérique universitaire qui comprend votre parcours
                        et besoins</p>
                    <p class="text-center"><a class="btn"
                            href="https://play.google.com/store/apps/details?id=com.enovsky.campusvirtuel"><img
                                src="assets/logos/google_play.svg" width="200" draggable="false"></a>
                        <a class="btn" href="https://apps.apple.com/fr/app/campus-virtuel/id6446039486?l=en"><img
                                src="assets/logos/apple_logo.svg" width="200" draggable="false"></a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="main" class="site-main">
    <section id="about" class="py-11">
        <div class="container text-center">
            <div class="row mb-7">
                <h2 class="section-title mb-7">Qui sommes-nous ?</h2>
                <div style="max-width: 875px; margin: auto;">
                    <p>ENOVSKY - Une société basée en France qui a mis en place le projet Virtual Campus pour
                        révolutionner la méthode d'apprentissage des étudiants grâce à l'utilisation des méthodes de
                        l'intelligence artificielle d'extraction de contenus textuels. Nous proposons des contenus
                        spécifiques du parcours des étudiants.</p>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayout.xs="column">
                <div fxFlex="33.33">
                    <div class="icon" style="color: #fff; font-size: 3em; text-align: center;">
                        <i class="fas fa-folder-open"></i>
                    </div>
                    <h3>Accès aux Contenus des Modules</h3>
                    <p>Nous assurons la persistance de tous vos contenus (Examens, Devoirs, Contrôles continus,
                        Exercises, Travaux Dirigés, Cours, Vidéos, ...) de votre parcours afin de vous limiter le
                        manque d'accès.</p>
                </div>
                <div fxFlex="33.33">
                    <div class="icon" style="color: #fff; font-size: 3em; text-align: center;">
                        <i class="fas fa-folder-plus"></i>
                    </div>
                    <h3>Recommandation</h3>
                    <p>Nous nous basons sur vos préférences pour vous recommander grace à l'intelligence
                        artificielle des modules pertinents.</p>
                </div>
                <div fxFlex="33.33">
                    <div class="icon" style="color: #fff; font-size: 3em; text-align: center;">
                        <i class="fas fa-arrow-up-right-dots"></i>
                    </div>
                    <h3>No Limit</h3>
                    <p>En plus des contenus de votre parcours vous pouvez vous inscrire à des modules présents dans
                        d'autres facultés pour approfondir vos connaissance ou pour en faire votre UE Libre.</p>
                </div>
            </div>
        </div>
    </section>

    <section id="fonctions" style="background-color: #F8F9FA; padding: 20px;">
        <div class="container">
            <div fxLayout="row wrap" fxLayout.xs="column">
                <div fxFlex="50" style="min-width: 470px;">
                    <style>
                        .superposition {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            padding-top: 100%;
                        }

                        /* on personnalise la position de la seconde image */
                        .superposition>div {
                            position: absolute;
                            width: 50%;
                            height: 100%;
                            top: 0;
                        }

                        .superposition>div>img {
                            width: 100%;
                        }

                        .image-2 {
                            left: 50%;
                        }

                        .superposition img {
                            height: 100%;
                            max-width: 100%;
                        }
                    </style>
                    <figure class="superposition">
                        <div>
                            <img src="assets/images/facultes_clean.png" class="img-fluid" alt="Qui-sommes-Nous"
                                draggable="false">
                        </div>
                        <div class="image-2">
                            <img src="assets/images/document_clean.png" class="img-fluid" alt="Qui-sommes-Nous"
                                draggable="false">
                        </div>
                    </figure>

                </div>
                <div fxFlex="50" style="min-width: 470px;">
                    <div>
                        <h2 class="section-title text-center">Fonctionnalités</h2>
                        <ul class="mb-3" style="list-style: none;">
                            <li>- Intuitive et facile d'utilisation avec des accès rapides,</li><br>
                            <li>- 100 % accessiible en mode offline (sans connexion),</li><br>
                            <li>- Synchronisation temps réel en mode online,</li><br>
                            <li>- Sauvegarde d'état continue (Vous reprenez où vous l'avez laissé),</li><br>
                            <li>- Recommandation de nouveaux contenus.</li><br>
                        </ul>
                        <p class="text-center"><a class="btn"
                                href="https://play.google.com/store/apps/details?id=com.enovsky.campusvirtuel"><img
                                    src="assets/logos/google_play.svg" width="200" draggable="false"></a>
                            <a class="btn"
                                href="https://apps.apple.com/fr/app/campus-virtuel/id6446039486?l=en"><img
                                    src="assets/logos/apple_logo.svg" width="200" draggable="false"></a>
                        </p>
                        <div class="text-center"><button class="btn btn-primary" type="button" id="demobtn"
                                onclick="displaydemo('demo_container','demobtn')">Play demo</button></div>

                        <script>

                            function displaydemo(demo, demobtn) {
                                let modal = document.createElement("div");
                                let modalContainer = document.createElement("div");
                                modal.classList.add("modal", "fade");
                                modalContainer.innerHTML = `<div id="demo_container" class="modal-dialog modal-xl modal-content" >
                    <video controls preload="metadata" style="width: 100%; height: 50%" loop autoplay>
                      <source src="https://enovsky.com/videos/campus_virtuel_presentation.mov">video can't play
                    </video>
                  </div>`;
                                modal.setAttribute("role", "dialog");
                                console.log(modal)
                                modal.appendChild(modalContainer);
                                $(modal).modal('show');

                                $(modal).on('hide.bs.modal', function () {
                                    modal.innerHTML = ''
                                });
                            }
                        </script>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="why-cv" class="py-11">
        <div class="container">
            <div class="row">
                <div class="text-center mb-7">
                    <h2 class="section-title">Pourquoi Virtual Campus ?</h2>
                    <p></p>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayout.xs="column">
                <div id="why-challenges" class="col-lg-5 d-flex align-items-center">
                    <div class="w-100">
                        <h3>Problèmes des étudiants</h3>
                        <div class="why-item">
                            <h4>Problème d'accès aux contenus sur l'historique des Ecoles et Universités</h4>
                            <p>Difficultés des étudiants à trouver des anciennes épreuves.</p>
                        </div>
                        <div class="why-item">
                            <h4>Coût d'accès aux contenus élevés</h4>
                            <p>Les étudiants sont obligés de faire des dépenses dans les photocopies, travaux
                                dirigés et fascicules payants.</p>
                        </div>
                        <div class="why-item">
                            <h4>Difficultés d'accès continuel à l'internet</h4>
                            <p>Beaucoup d'étudiants n'ont pas de wifi à domicile pour continuer à avoir accès aux
                                contenus en ligne, problème de connexion.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 text-center d-flex align-items-center">
                    <div class="w-100 text-center">
                        <img style="width: 260px; margin: 2rem 0 3rem;" src="public/module/bg-reasons.png">
                    </div>
                </div>
                <div id="why-solutions" class="col-lg-5 text-end d-flex align-items-center justify-content-md-end">
                    <div class="w-md-80 w-100">
                        <h3>Solutions de Virtual Campus</h3>
                        <div class="why-item">
                            <h4>Assistance IA Personnalisée</h4>
                            <p>Un Moteur d'IA Avancé d'assistance personnalisée GPT4. Une aide précieuse pour la résolution de problèmes académiques.</p>
                        </div>
                        <div class="why-item">
                            <h4>Outil Collaboratif: VirtualChat</h4>
                            <p>Créer et gérer facilement des groupes de travail, cet outil intégré favorise le travail en groupe entre étudiants.</p>
                        </div>
                        <div class="why-item">
                            <h4>Nous assurons l'accès à des contenus anciens comme nouveaux</h4>
                            <p>Les étudiants pourront avoir un accès facile à des contenus de leurs départements.
                            </p>
                        </div>
                        <div class="why-item">
                            <h4>Avec Virtual Campus, nous divisons le coût de vos photocopies drastiquement.</h4>
                            <p>Avec un coût assez bas, vous avez meme accès à des contenus des modules des autres
                                départements en étant écologique.</p>
                        </div>
                        <div class="why-item">
                            <h4>Accès en mode hors connexion</h4>
                            <p>Nous avons pensé à la réalité des étudiants, tous les contenus sont accessibles en
                                hors connexion dès qu'ils sont chargés.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
<section id="footer" style="padding: 20px;">
    <div class="container">
        <div fxLayout="row wrap" fxLayout.xs="column">
            <div class="col-lg-4 mb-4 mb-lg-0">
                <div class="footer-box">
                    <h4>ENOVSKY</h4>
                    <ul>
                        <li><a href="#"><i class="fas fa-location-dot"></i> Villepinte - France</a></li>
                        <li><a href="#"><i class="fas fa-phone"></i> +33 (0) 6 60 49 69 69</a></li>
                        <li><a href="#"><i class="fas fa-envelope"></i> contact@enovsky.com</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
                <div class="footer-box">
                    <h4>Nos Partenaires</h4>
                    <div class="row">
                        <div class="col-sm-6 mb-3 mb-sm-0">
                            <ul>
                                <li><a href="https://www.facebook.com/CDGenie/"><img
                                            src="assets/images/ces_du_genie.png" width="100" draggable="false"></a>
                                </li>
                                <li><a href="#">Afrik Apps Home</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="footer-box">
                    <h4>Newsletter</h4>
                    <p>Restez informé sur les évènements ENOVSKY</p>
                    <div id="newsletter">
                        <form action="#">
                            <div class="input-group">
                                <input type="text" class="form-control" name="email"
                                    placeholder="Votre adresse mail ici">
                                <div class="input-group-btn">
                                    <a href="#" class="btn btn-default"><i class="fas fa-arrow-right fa-1x"></i></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 20px;"></div>
        <div id="copyright" fxLayout="row wrap">
            <div class="col-lg-6 footer-social mb-5 text-center text-lg-end order-lg-2">
                <a href="https://www.facebook.com/campusvirtuel"><i class="fab fa-facebook-f"></i></a>
                <a href="https://www.linkedin.com/company/enovsky"><i class="fab fa-linkedin-in"></i></a>
                <a href="https://twitter.com/_JMKD_"><i class="fab fa-twitter"></i></a>
            </div>
            <div class="col-lg-6 text-center text-lg-start">
                <p class="pt-2"><small>Copyright © {{currentYear}} <a href="/" title="ENOVSKY" rel="home">ENOVSKY</a>. Tous
                        droits réservés.<span class="d-none d-md-inline"> | </span><a class="d-block d-md-inline"
                            href="https://enovsky.com/policy">Politique &amp; Conditions d'Utilisation</a></small>
                </p>
            </div>
        </div>
    </div>
</section>
