import { Component, ElementRef, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { TableComponent } from 'src/app/back-office/components/table/table.component';
import config from 'config/default.json';
@Component({
  selector: 'app-vca-student-manager',
  templateUrl: './vca-student-manager.component.html',
  styleUrls: ['./vca-student-manager.component.scss', '../../components/table/table.component.scss']
})
export class VcaStudentManagerComponent extends TableComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  platformInfos:{[x: string]: any} = {origin: 'virtualcampus', key: 'vca', root: 'VIRTUALCAMPUS'};
  @Output() inProgress = new EventEmitter<any>();
  @Input() userInfos: { [x:string]: any } = new Map();
  modes:{ [x:string]: string|number }[] = [];
  pageId = 'student'
  platforms: any[] = [];
  
  
  
  tableData: { [x:string]: string|number }[] = []; 

  ngOnInit(): void { }
  toggleRow(row:any, clickedElm:any){
    if(clickedElm.tagName != 'INPUT'){
      row['expanded'] = !row['expanded'];
    }
  }
  
  loadStudentData(data:any){
    this.inProgress.emit(true);
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/student/load', {filter_data: data, platform_infos: this.platformInfos}).subscribe((response)=>{
      this.dataSource = response.map((row) => {
        row["expanded"] = false;
        row["checked"] = false;
        return row;
      });
      this.showedRows = this.dataSource;
      this.platforms = Array.from(new Set(response.map(el=>el.platform)));
      this.inProgress.emit(false);
    }) 
  }


  sendData(checkedAllRef:any){

    //console.log(this.selectedRows);
  }
  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.cdRef.detectChanges();
  }

}
