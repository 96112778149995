import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import config from 'config/default.json';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {}
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() data:{[x: string]: any} = {} ;
  @Input() platformInfos: { [x:string]: any } = new Map();
  chartEvolution: Chart | null = null;
  chartGlobalProjectContent: Chart | null = null;
  chartProjectContent: Chart | null = null;
  chartGlobalRawContent: Chart | null = null;
  chartRawContent: Chart | null = null;
  chartContent: Chart | null = null;
  


  ngOnInit(): void {
    //this.buildData();
    if( this.data['data'] && Object.keys(this.data['data']).length > 0) this.createVisualization();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange && Object.keys(changes['data']).length > 0) this.buildData();
  }
  buildData(){
    if(this.chartEvolution) (this.chartEvolution as Chart).destroy()
    if(this.chartGlobalProjectContent) (this.chartGlobalProjectContent as Chart).destroy()
    if(this.chartProjectContent) (this.chartProjectContent as Chart).destroy()
    if(this.chartGlobalRawContent) (this.chartGlobalRawContent as Chart).destroy()
    if(this.chartRawContent) (this.chartRawContent as Chart).destroy()
    if(this.chartContent) (this.chartContent as Chart).destroy()
    
    this.createVisualization();
  }
  async createVisualization() {
    /* EVOLUTION  */
  this.chartEvolution = new Chart(this.elRef.nativeElement.querySelector('.student-evolution'), {
    type: 'line',
    data: {
        labels: this.data['evolution'].abscissa,
        datasets: [{
            label: 'Total Monthly registered',
            fill: false,
            backgroundColor: '#36A2EB',
            borderColor: '#36A2EB',
            data: this.data['evolution'].total_student
        },{
          label: 'Monthly registered',
          fill: false,
          backgroundColor: '#4BC0C0',
          borderColor: '#4BC0C0',
          data: this.data['evolution'].nb_student
      }
]
    },
    options: {
        responsive: true, 
        maintainAspectRatio: false,
        scales: { 
          x: {
            title: {
              display: true,
              text: 'Months',
          }
          },
          y: {
            title: {
              display: true,
              text: 'Number of register',
            },
            beginAtZero: true,
          }
        },
        animation: {
          duration: 1,
          onComplete: function () {
            }
          }
    }
  });
  
//Global Project Content
this.chartGlobalProjectContent = new Chart(this.elRef.nativeElement.querySelector('.global-project-content'),{
  type: 'pie',
  data: {
    labels: ['Proccessing', 'Proccessed'],
    datasets: [
      {
        label: 'Value',
        data: [this.data['raw_project'].proccessing_content, this.data['raw_project'].proccessed_content],
        backgroundColor: ['#ec9f53', '#016F86'],
      }
    ]
  },
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Proccessed Contents'
      }
    }
  },
}
);

//Project Content
  this.chartProjectContent = new Chart(this.elRef.nativeElement.querySelector('.project-content'),{
    type: 'bar',
    data:  {
			labels: this.data['project_content'].abscissa,
			datasets: [{
				label: 'Number Content',
				backgroundColor: '#36A2EB',
				borderColor: '#36A2EB',
				data: this.data['project_content'].nb_content,
          borderWidth: 1
			},
      {
				label: 'Splitted',
				backgroundColor: '#ec9f53',
				borderColor: '#ec9f53',
				data: this.data['project_content'].nb_splitted,
          borderWidth: 1
			},
      {
				label: 'Classified',
				backgroundColor: '#4BC0C0',
				borderColor: '#4BC0C0',
				data: this.data['project_content'].nb_classified,
          borderWidth: 1
			},
      {
				label: 'Edited',
				backgroundColor: '#016F86',
				borderColor: '#016F86',
				data: this.data['project_content'].nb_edited,
          borderWidth: 1
			},
      {
				label: 'Imported',
				backgroundColor: '#2E8B57',
				borderColor: '#2E8B57',
				data: this.data['project_content'].nb_imported,
          borderWidth: 1
			}]
		},
    options: {
      layout: {padding: {right: 18}},
      scales: {
          x: {
              min: 0,
              ticks: {
                //minRotation: 70
              }
            },
            y: {
              min: 0,
              beginAtZero: true
            },
      },
      
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 2,
        onComplete: function () {
            let ctx = this.ctx;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function (dataset, i) {
            });  
        }
      }
  }

});
//Global Raw Content
this.chartGlobalRawContent = new Chart(this.elRef.nativeElement.querySelector('.global-raw-content'),{
  type: 'pie',
  data: {
    labels: ['In Project', 'Out of Project'],
    datasets: [
      {
        label: 'Value',
        data: [this.data['raw_project'].with_project, this.data['raw_project'].without_project],
        backgroundColor: ['#016F86', '#ec9f53'],
      }
    ]
  },
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Project Contents'
      }
    }
  },
}
);

//Raw Content
  this.chartRawContent = new Chart(this.elRef.nativeElement.querySelector('.raw-content'),{
    type: 'bar',
    data:  {
			labels: this.data['raw_content'].abscissa,
			datasets: [{
				label: 'In Project',
				backgroundColor: '#016F86',
				borderColor: '#016F86',
				data: this.data['raw_content'].with_project,
          borderWidth: 1
			},
      {
				label: 'Out of Project',
				backgroundColor: '#ec9f53',
				borderColor: '#ec9f53',
				data: this.data['raw_content'].without_project,
          borderWidth: 1
			}]
		},
    options: {
      layout: {padding: {right: 18}},
      scales: {
          x: {
              min: 0,
              ticks: {
                //minRotation: 70
              }
            },
            y: {
              min: 0,
              beginAtZero: true
            },
      },
      
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 2,
        onComplete: function () {
            let ctx = this.ctx;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function (dataset, i) {
            });  
        }
      }
  }

});
//Available Content
this.chartContent = new Chart(this.elRef.nativeElement.querySelector('.available-content'),{
  type: 'bar',
  data:  {
    labels: this.data['content'].abscissa,
    datasets: [{
      label: 'Nombre de contenus',
      backgroundColor: '#36A2EB',
      borderColor: '#36A2EB',
      data: this.data['content'].ordinate,
        borderWidth: 1
    }]
  },
  options: {
    layout: {padding: {right: 18}},
    scales: {
        x: {
            min: 0,
            ticks: {
              //minRotation: 70
            }
          },
          y: {
            min: 0,
            beginAtZero: true
          },
    },
    
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 100,
      easing: 'easeOutBounce',
      onComplete: function () {
          let ctx = this.ctx;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          this.data.datasets.forEach(function (dataset, i) {
          });  
      }
    }
}

});




  
  }
}
