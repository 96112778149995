import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


/* 
sudo npm install -g @angular/cli@15.2.9
ng new project_name
npm install pdfjs-dist,
npm install chart.js
npm install socket.io-client
ng add @angular/material

npm install chart.js --legacy-peer-deps

npm install bootstrap
ng generate component element
ng generate component element/detail
ng generate component back-office/virtualcampus/vca-advertisment
ng generate module modules/api-service

sudo ng serve --host 192.168.1.200 --disable-host-check --port 82
sudo pm2 start "ng serve --host 192.168.1.200 --disable-host-check --port 82"
sudo pkill -f :4200
sudo kill -9 $(sudo lsof -t -i:9001)

git push origin master --force

Dev
sudo ng serve --host 192.168.1.200 --disable-host-check --port 3001
*/